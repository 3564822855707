.maps-component {
    &.map-3-1 {
        padding-bottom: 33%;
    }

    &.map-2-1 {
        padding-bottom: 50%;
    }

    &.map-1-1 {
        padding-bottom: 100%;
    }
}